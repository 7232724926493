import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import Content from "../components/Content";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Button from "../components/ui/Button";
import { SEO } from "../components/SEO";

type Person = {
  name: string;
  role: string;
  image?: React.ReactElement;
  linkedinUrl?: string;
};

const people: Array<Person> = [
  {
    name: "Tim Helin",
    role: "Owner",
    image: (
      <StaticImage
        src="../images/team/tim.jpg"
        alt=""
        width={1024}
        height={1024}
      />
    ),
    linkedinUrl: "https://www.linkedin.com/in/timhelin/",
  },
  {
    name: "Cole",
    role: "Carpenter",
    image: (
      <StaticImage
        src="../images/team/cole.jpg"
        alt=""
        width={1024}
        height={1024}
      />
    ),
  },
  {
    name: "Carson",
    role: "Carpenter",
    image: (
      <StaticImage
        src="../images/team/carson.jpg"
        alt=""
        width={1024}
        height={1024}
      />
    ),
  },
];

// markup
function OurCompany() {
  return (
    <Content light>
      <h2 className="font-serif text-3xl font-extrabold text-gray-900 sm:text-4xl">
        Our Company
      </h2>
      <div className="mx-auto max-w-lg md:grid md:max-w-none md:grid-cols-2 md:gap-8">
        <div>
          <div className="mt-3">
            <p className="my-4 text-lg text-gray-500">
              Founded from the desire to create beautiful things, Helin Co has
              been building and remodeling homes for over 12 years. Focusing
              exclusively in residential design and building, we have built
              unique homes in many of the great neighborhoods that the Twin
              Cities has to offer.
            </p>
            <p className="my-4 text-lg text-gray-500">
              Since the beginning we have loved a good project. Large or small,
              grand or subtle, our team excels at building dream homes for our
              clients. Our main concern is building sustainable houses that work
              for life.
            </p>
            <p className="my-4 text-lg text-gray-500">
              If you&apos;re looking for a home, or just a remodel, we're here
              to help!
            </p>
            <Button to="/contact" className="my-6 text-sm md:mb-0">
              Contact Us
            </Button>
          </div>
        </div>
        <div>
          <StaticImage
            src="../images/team/team1.jpg"
            alt="Helin Co Team of Employees"
            className="rounded-lg object-cover shadow-lg"
          />
        </div>
      </div>
    </Content>
  );
}

function OurProcess() {
  return (
    <Content>
      <div className="mx-auto max-w-lg md:grid md:max-w-none md:grid-flow-dense md:grid-cols-2 md:gap-8">
        <div className="md:col-start-2">
          <h2 className="font-serif text-3xl font-extrabold text-gray-900 sm:text-4xl">
            Our Process
          </h2>
          <div className="mt-3">
            <p className="text-lg text-gray-500">
              We love a good project. Our team excels at building dream homes
              for our clients. If you&apos;re looking for a home in the greater
              Twin Cities area, let us know!
            </p>
            <Button to="/contact" className="my-6 text-sm md:mb-0">
              Contact Us
            </Button>
          </div>
        </div>
        <div className="md:col-start-1">
          <StaticImage
            src="../images/feature-process.jpg"
            alt="Helin Co Blueprint Process"
            className="rounded-lg object-cover shadow-lg"
          />
        </div>
      </div>
    </Content>
  );
}

function OurTeam() {
  return (
    <Content light>
      <div className="space-y-12">
        <div className="space-y-5 sm:space-y-4 md:max-w-xl lg:max-w-3xl xl:max-w-none">
          <h2 className="font-serif text-2xl font-extrabold tracking-tight sm:text-3xl">
            Our Team
          </h2>
          <p className="text-xl text-gray-500">
            Our team is dedicated to building the best homes using modern
            construction techniques. We are passionate about the construction
            process and building homes that not only look great, but that
            enhance people's lives.
          </p>
        </div>
        <ul className="space-y-12 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 sm:space-y-0 lg:grid-cols-3 lg:gap-x-8">
          {people.map((person) => (
            <li key={person.name}>
              <div className="space-y-4">
                <div className="aspect-w-3 aspect-h-2">
                  {person.image ? (
                    React.cloneElement(person.image, {
                      className: "object-cover shadow-lg rounded-lg",
                    })
                  ) : (
                    <img
                      src="data:image/svg+xml;charset=utf-8,%3Csvg height='1024' width='1024' xmlns='http://www.w3.org/2000/svg' version='1.1'%3E%3C/svg%3E"
                      alt="Missing"
                      className="rounded-lg object-cover shadow-lg"
                    />
                  )}
                </div>
                <div className="space-y-2">
                  <div className="space-y-1 text-lg font-medium leading-6">
                    <h3>{person.name}</h3>
                    <p className="text-primary-600">{person.role}</p>
                  </div>
                  <ul className="flex space-x-5">
                    {person.linkedinUrl && (
                      <li>
                        <a
                          href={person.linkedinUrl}
                          target="_blank"
                          className="text-gray-400 hover:text-gray-500"
                        >
                          <span className="sr-only">LinkedIn</span>
                          <svg
                            className="h-5 w-5"
                            aria-hidden="true"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                          >
                            <path
                              fillRule="evenodd"
                              d="M16.338 16.338H13.67V12.16c0-.995-.017-2.277-1.387-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248H8.014v-8.59h2.559v1.174h.037c.356-.675 1.227-1.387 2.526-1.387 2.703 0 3.203 1.778 3.203 4.092v4.711zM5.005 6.575a1.548 1.548 0 11-.003-3.096 1.548 1.548 0 01.003 3.096zm-1.337 9.763H6.34v-8.59H3.667v8.59zM17.668 1H2.328C1.595 1 1 1.581 1 2.298v15.403C1 18.418 1.595 19 2.328 19h15.34c.734 0 1.332-.582 1.332-1.299V2.298C19 1.581 18.402 1 17.668 1z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </a>
                      </li>
                    )}
                  </ul>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </Content>
  );
}

function AboutPage() {
  return (
    <>
      <Header />
      <div className="bg-gray-50 py-16 sm:py-24">
        <div className="mx-auto max-w-md pl-4 pr-8 sm:max-w-lg sm:px-6 lg:max-w-7xl lg:px-8">
          <h1 className="text-center font-serif text-4xl font-extrabold leading-10 tracking-tight text-gray-900 sm:text-5xl sm:leading-none lg:text-6xl">
            About Us
          </h1>
          <p className="mx-auto mt-6 max-w-3xl text-center text-xl leading-normal text-gray-500">
            Our company is dedicated to building the best projects in Minnesota!
          </p>
        </div>
      </div>
      <OurCompany />
      <OurProcess />
      <OurTeam />
      <Footer />
    </>
  );
}

export default AboutPage;

export const Head = () => <SEO title="About Us" />;
